.gb-block-main {
  background: linear-gradient(180deg, #000204 14.55%, #011313 100%);
  color: #ffffff;
  .gb-block {
    padding: 30px;
    margin: auto;
    display: flex;
    gap: 30px;
    width: 85%;
    justify-content: space-around;
    align-items: center;

    @include max-break-point(tablet-landscape) {
      gap: 30px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .block-image {
      @include max-break-point(mobile-phone) {
        width: 90%;
        // height: 225px;
      }
    }
    .yt-video {
      @include max-break-point(mobile-phone) {
        width: 100%;
        height: 225px;
      }
      iframe {
        border-radius: 8px;
        width: 600px;
        height: 310px;
        @include max-break-point(mobile-phone) {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .gb-logo-desc {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    gap: 20px;
    @include max-break-point(mobile-phone) {
      width: 100%;
    }
    .btn {
      @include max-break-point(tablet-landscape) {
        align-self: center;
      }
    }
    .logo {
      width: 320px;
      @include max-break-point(mobile-phone) {
        width: 306px;
      }
      @include max-break-point(tablet-landscape) {
        width: 306px;
        align-self: center;
      }
      .agora-logo {
        width: 320px !important;
      }
    }
    .gb-desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      opacity: 0.6;
    }
  }
}

.btn-group-web {
  display: flex;
  gap: 20px;
  @include max-break-point(mobile-phone) {
    display: none;
  }
}

.btn-group-mobile {
  display: none;
  @include max-break-point(mobile-phone) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
}

.react-multiple-carousel__arrow {
  z-index: 1 !important;
}

.block-image-skeleton {
  height: 310px;
  width: 570px !important;
  border-radius: 8px;
  width: 80%;
  @include max-break-point(mobile-phone) {
    width: 290px !important;
    height: 201px !important;
  }
}

.gangstabet-id-info {
  display: flex;
  gap: 5px;
  align-items: center;
  span {
    cursor: pointer;
  }
}
