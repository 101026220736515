* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h4 {
  font-family: var(--exo);
}

h3,
h5 {
  font-family: var(--exo);
}
.h6 {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.45px;
  font-family: var(--exo);
}
.h3 {
  font-weight: 600;
  font-size: 48px;
  line-height: 65.57px;
  font-family: var(--exo);
}
div,
span,
p,
button,
input {
  font-family: var(--poppins);
}

h1 {
  font-weight: 400;
  font-size: 8rem;
  line-height: 98%;
  letter-spacing: -0.094rem;
  text-transform: uppercase;
}

h2 {
  font-weight: 400;
  font-size: 5.5rem;
  line-height: 98%;
  letter-spacing: -0.031rem;
  text-transform: uppercase;
}

h3 {
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.625rem;
}

h4 {
  font-weight: 400;
  font-size: 2.625rem;
  line-height: 120%;
  // line-height: 0%;
  letter-spacing: 0.0156rem;
  text-transform: uppercase;
}

h5 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 110%;
}

h6 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: 0.009rem;
  font-family: var(--exo);
}

//reserved(r) - type - number
.r-subtitle-1 {
  font-family: var(--poppins);
  font-weight: 600;
  font-size: 18px;
  line-height: 28.8px;
  letter-spacing: 0.009rem;
}

.r-subtitle-2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  letter-spacing: 0.1px;
}

.r-body-1 {
  font-family: var(--poppins);
  font-weight: 400;
  font-size: 15px;
  // line-height: 25.6px;
  letter-spacing: 0.031rem;
}

.text-center {
  text-align: center;
}

.r-body-2 {
  font-weight: 400;
  font-size: 0.875rem;
  // line-height: 1.75rem;
  letter-spacing: 0.031rem;
}

.r-caption {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.39px;
  letter-spacing: 1px !important;
}

.r-caption-1 {
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 0.004em;
}

.r-overline {
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 0.015rem;
  text-transform: uppercase;
}
.w-100 {
  width: 100%;
}

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.r-container {
  box-sizing: border-box;
  max-width: 72.375rem !important;
  padding: 2rem 1rem 1rem 1rem !important;
  margin: auto !important;
  display: flex;
  flex-direction: column;
  min-height: 89vh;
}

.r-text-center {
  text-align: center !important;
}

.primary-color {
  color: var(--primary-color);
}
.borderless-btn {
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  display: flex;
}
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-default-width {
  width: 100%;
  height: 100%;
}

@keyframes skeleton-loading {
  0% {
    background-color: #4b4b4b;
  }
  100% {
    background-color: #242424;
  }
}
