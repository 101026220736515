.header {
  &-container {
    // position: absolute;
    position: fixed;
    width: 100%;
    z-index: 999;
    background: #070E131A;
    backdrop-filter: blur(8px);
  }
  &-wrapper {
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    width: 90%;
    padding: 18px;
    margin: auto;
  }

  &-logo {
    color: var(--white);
    font-size: 32px;
    font-weight: 500;
    cursor: pointer;

    span {
      font-weight: 300;
    }
  }

  &-burger {
    cursor: pointer;
    display: none;
    @media screen and (max-width: 992px) {
      display: block;
    }
  }
}

@include max-break-point(large-desktop) {
  .header {
    &-wrapper {
      width: 90%;
    }
  }
}

@include max-break-point(tablet-landscape) {
}

@include max-break-point(tablet) {
  .header {
    &-wrapper {
      width: 90%;
    }
    &-logo {
      font-size: 25px;
    }
  }
}

@include max-break-point(mobile-phone) {
  .header {
    &-wrapper {
      width: 95%;
      padding: 20px;
    }
    &-logo {
      font-size: 18px;
    }
  }
}

.header-social-icons {
  a {
    text-decoration: none;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: linear-gradient(94.23deg, #079B9D -6.45%, #2C7EAD 115.88%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.navbar-wrapper {
  display: flex;
  align-items: center;
  gap: 40px;

  @media screen and (max-width: 900px) {
    display: none;
  }

  .navbar-items {
    font-family: Exo;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    color: #D5D9DC;
    cursor: pointer;

  }
}

.header-social-icons {
  display: flex;
  align-items: center;
  gap: 14px;
}