.mint-wrapper {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.36) 0%,
      rgba(0, 0, 0, 0.7) 71.59%
    ),
    url("../../../images/illustration/mintBg.svg");
  background-repeat: no-repeat;
  // background-size: cover;
  @include max-break-point(tablet) {
    background-size: 100vh;
  }
  background-position: center top;
}
.mint-banner-text {
  max-width: 1440px;
  width: 90%;
  display: flex;
  margin: auto;
  padding-top: 10%;
  padding-bottom: 10%;
  @include max-break-point(tablet) {
    flex-direction: column;
    gap: 20px;
  }
  justify-content: space-between;
  .mint-banner-info {
    @include max-break-point(tablet-landscape) {
      width: 60%;
    }
    @include max-break-point(tablet) {
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    .mint-info-heading {
      font-weight: 700;
      font-size: 50px;
      line-height: 120%;
      letter-spacing: -0.5px;
      color: #ffffff;
      @include max-break-point(tablet) {
        font-size: 30px;
        text-align: center;
      }
      //   @include max-break-point(tablet) {
      //     font-size: 30px;
      //     text-align: center;
      //   }
    }
    .mint-info-desc {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
      @include max-break-point(tablet) {
        text-align: center;
      }
    }
    .mint-info-note {
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.0025em;
      color: #ffffff;
      opacity: 0.8;
      @include max-break-point(tablet) {
        text-align: center;
      }
    }
  }
  .mint-banner-illustration {
    @include max-break-point(tablet) {
      align-self: center;
    }
    img {
      width: 468px;
      @include max-break-point(tablet-landscape) {
        width: 300px;
      }
    }
  }
}
.connect-wallet-btn {
  width: 246px !important;
}

.saleStatus {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #079b9d;
}

.wallet-connected-info {
  background: linear-gradient(
    156.52deg,
    rgba(1, 27, 32, 0.6) -3.19%,
    rgba(0, 2, 4, 0.6) 77.73%
  );
  max-width: 550px;
  @include max-break-point(tablet) {
    max-width: 90%;
    width: auto;
  }
  @include max-break-point(mobile-phone) {
    align-items: center;
    max-width: 100%;
  }
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #ffffff;
  backdrop-filter: blur(5.5px);
  .available-info {
    display: flex;
    justify-content: space-between;
    @include max-break-point(mobile-phone) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .available-number {
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      letter-spacing: -0.5px;
      color: #079b9d;
    }
    .wallet-address {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
      font-weight: 400;
      font-size: 16px;
      line-height: 27px;
      text-align: right;
      color: #079b9d;
    }
  }
  .mint-count {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    // letter-spacing: -0.5px;
    color: #079b9d;
    @include max-break-point(mobile-phone) {
      text-align: center;
    }
  }
  .wallet-desc {
    font-weight: 400;
    font-size: 14px;
    opacity: 0.8;
    line-height: 160%;
    letter-spacing: 0.005em;
    color: #ffffff;
    @include max-break-point(mobile-phone) {
      align-self: center;
      text-align: center;
    }
  }
  .wallet-info {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    @include max-break-point(mobile-phone) {
      flex-direction: column;
      justify-content: center;
    }
    .wallet-crown {
      display: flex;
      gap: 20px;
      .wallet-price-name {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .wallet-label-wallet {
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          letter-spacing: -0.5px;
          color: #ffffff;
        }
        .wallet-label-price {
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          letter-spacing: -0.5px;
          color: #ffffff;
        }
      }
      .wallet-price-value {
        .wallet-crown-log {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .wallet-value {
            display: flex;
            gap: 20px;
            img {
            }
            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 140%;
              letter-spacing: 0.15px;

              color: #ffffff;
            }
          }
          .price-value {
            display: flex;
            gap: 20px;
            img {
            }
            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 140%;
              letter-spacing: 0.15px;

              color: #ffffff;
            }
          }
        }
      }
    }
    .reserve-btn {
      align-self: flex-end;
    }
  }
}
