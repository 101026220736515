.ecosystem-card-wrapper {
    height: 220px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid #8CAABB80;
    // background: url('../../../images/gangstaverse/gangwar-icon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.bg-contain {
        background-size: contain;
    }

    @media screen and (max-width: 768px) {
         &:not(:last-child) {
            margin-bottom: 40px;
         }      
    }

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(15, 15, 15, 1) 0%, rgba(15, 15, 15, 0.734) 35%, rgba(15, 15, 15, 0.734) 100%);
        z-index: 3;
        top: 0;
        left: 0;
        border-radius: 12px;
    }

    &:hover {
        border: 2px solid #3786B2B2;
        box-shadow: 0px 0px 6px 0px #45B1ED59;
        transition: all 0.3s ease-in-out;
    }

    &.selected {
        border: 2px solid #45B1ED99;
        box-shadow: 0px 0px 26px 0px #45B1EDA6;
    }

    .ecosystem-card-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        width: 100%;
        z-index: 5;

        .card-title-image {
            max-width: 260px;
        }

        img {
            position: relative;
            z-index: 5;
        }
    }
}