@import "./fonts.styles";
@import "./variable.styles";
@import "./typescale.style";
@import "./mixins";
@import "./gangstastrap";

:root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #070E12;
  color: white;
}

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: #373737;
}

::-webkit-scrollbar-thumb {
  background: #079b9d;
}

::-moz-selection {
  color: var(--white);
  background: var(--primary-color);
}
::selection {
  color: var(--white);
  background: var(--primary-color);
}

img {
  @include disable-selection;
}
