.outline-modal-wrapper {
  width: 643px;
  display: flex;
  // background: rgba(0, 0, 0, 0.6);
  // backdrop-filter: blur(24px);
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow-y: auto;
  @include max-break-point(mobile-phone) {
    overflow: hidden !important;
  }
  max-height: 95vh;
  background: #ffffff;
  clip-path: polygon(
    80px 0%,
    calc(100%) 0%,
    100% 80px,
    100% calc(100% - 80px),
    calc(100% - 80px) 100%,
    80px 100%,
    0% calc(100%),
    0% 80px
  );
}

.modal-wrapper {
  background: linear-gradient(
    156.71deg,
    rgba(1, 27, 32, 0.9) -3.17%,
    rgba(0, 2, 4, 0.997) 77.24%
  );
  clip-path: polygon(
    80px 0%,
    calc(100%) 0%,
    100% 80px,
    100% calc(100% - 80px),
    calc(100% - 80px) 100%,
    80px 100%,
    0% calc(100%),
    0% 80px
  );
  transform: scale(0.99);
}

.deposit__withdraw__modal__btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.modal-close-btn {
  cursor: pointer;
  position: absolute;
  top: 30px;
  z-index: 999;
  right: 25px;
}

.modal-overlay {
  backdrop-filter: blur(1px);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  // backdrop-filter: blur(2px);
}

.modal-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 50px;
}

.modal-content {
  .modal-title {
    text-align: center;
    font-weight: 500;
  }
  .modal-seperator {
    display: block;
    margin: 16px auto;
  }
}

.model-custom-text {
  text-align: center;
  color: #c9ccd9;
  font-weight: 500;
  font-size: 13px;
  // line-height: 19px;
  letter-spacing: 0.5px;
}

.model-custom-content {
  display: flex;
  padding: 20px;
  .modal-btn-wrapper {
    padding: 20px 60px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  .modal-btn-wrapper:first-child {
    border-right: 1px solid white;
  }
  .modal-btn-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.15px;
  }
}

.error-message {
  // color: #eb5757;
  font-size: 14px;
  color: #bf4e4e;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.5px !important;
  // margin-bottom: 3px;
  margin-top: 10px;
}

.modal-input-box {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;
  .modal-input-amt {
    width: 301px;
    height: 42px;
    text-align: center;
    background-color: #242129;
    border: 2px solid var(--primary-color);
    font-weight: 700;
    font-size: 16px;
    color: #e5e8eb;
    &:focus {
      outline: none;
    }
  }
  .disable-deposit-withdraw-box {
    cursor: not-allowed;
  }
  .modal-image-crown {
    position: absolute;
    top: 13px;
    left: 15px;
  }
}

.modal-available-crown {
  .available-text {
    // color: #079b9d;
    color: var(--primary-color);
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.5px !important;
    margin-bottom: 18px;
  }
}

input {
  background: url(../../../images/logo/crown.svg) no-repeat scroll 12px 12px;
}

@include max-break-point(mobile-phone) {
  .outline-modal-wrapper {
    width: 360px !important;
  }
  .modal-wrapper {
    width: 360px !important;
  }
}

// loading
@keyframes loading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.loading div {
  position: absolute;
  width: 122.13px;
  height: 122.13px;
  border: 1.77px solid #112b30;
  border-top-color: transparent;
  border-radius: 50%;
}
.loading div {
  animation: loading 1s linear infinite;
  top: 88.5px;
  left: 88.5px;
}
.loadingio-spinner-rolling-vz439jxau8e {
  width: 177px;
  height: 177px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.loading {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.loading div {
  box-sizing: content-box;
}
