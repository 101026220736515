.main-menu-item-wraper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: #0002048f;
  overflow: hidden;
  backdrop-filter: blur(2px);
  -webkit-scrollbar {
    display: none !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
.menuItems {
  position: fixed;
  top: 0;
  right: 0;
  padding: 25px;
  width: 100%;
  background-color: #111C22;
  box-shadow: -12px 0px 32px rgba(0, 0, 0, 0.12);
  overflow-y: scroll;
  max-height: 100%;

  @include max-break-point(tablet) {
    width: 100%;
  }
  &-logo {
    position: absolute;
    top: 20px;
    right: 20px;
    
    .close {
      cursor: pointer;
      color: white;
    }
  }

  &-allItems {
    height: 100vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none !important;
    }
  }
}
.unClickable {
  cursor: not-allowed !important;
  &:hover {
    opacity: 0.7 !important;
  }
}


.navbar-wrapper-mb {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;

  .navbar-items {
    font-family: 'Exo';
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    text-transform: uppercase;
    color: #D5D9DC;
    cursor: pointer;

  }
}