.homepage-wrapper {
    // padding-top: 100px;
    overflow: hidden;
}
.hero-section {
    padding-top: 150px;
    background: #070E12;
    width: 100%;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

   
    

    .hero-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
        position: relative;
        // z-index: 16;

        @media screen and (max-width: 874px) {
            padding-left: 30px;
            padding-right: 30px;
        }

        h3 {
            font-family: 'Exo 2';
            font-size: 72px;
            font-weight: 700;
            line-height: 78px;
            letter-spacing: -2px;
            text-align: center;
            background: linear-gradient(94.23deg, #079B9D -6.45%, #2C7EAD 115.88%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            text-transform: uppercase;
            position: relative;
            z-index: 12;

            @media screen  and (max-width: 768px){
               
                font-size: 48px;
                font-weight: 700;
                line-height: 56px;


                .hide-md {
                    display: none;
                }
                
                
                
            }

        }

        p {
            font-family: 'Exo';
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            color: #A0ACB4;
            width: 60%;
            margin: 14px auto;
            position: relative;
            z-index: 12;

            @media screen and (max-width: 768px) {
                width: 100%;
                font-size: 16px;
                line-height: 26px;
            }

        }

         button {
            position: relative;
            z-index: 12;
         }

        .glow-gradient-circle {
            position: absolute;
            width: 400px;
            height: 400px;
            border-radius: 50%;
            background: #457785;
            mix-blend-mode: color-dodge;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            filter: blur(26px);
            opacity: 0.5;
            z-index: 10;

            @media screen and (max-width: 680px) {
                height: 300px;
                width: 300px;
            }
        }
    }
    .city-bg {
        margin-top: -80px;
        min-height: 600px;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-x: hidden;

        .city-background {
            height: 100%;
            width: 100%;
            background: url('../../../images/banner/g_city.png');
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            mix-blend-mode: color-dodge;
            z-index: 0;
        } 

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 200px;
            clip-path: polygon(0 0,50% 140px,100% 0,100% 100%,0 100%);
            bottom: 40px;
            left: 0;
            background:#070E12;
            filter: blur(36px);
            overflow: hidden;
            
            
        }
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 200px;
            z-index: 6;
            bottom: 80px;
            left: 0;
            background-image: url('../../../images/common/hero_cutout.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            filter: blur(36px);
            overflow: hidden;
        }

        .mid-hero-content {
            // margin-top: -180px;
            position: relative;
            z-index: 20;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (max-width: 830px) {
                width: 75%;
            }

            @media screen and (max-width: 475px) {
                width: 90%;
                margin-top: 40px;
            }
            
            .stats-mb {
                display: none;

                @media screen and (max-width: 820px) {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    width: 100%;
                }

                .stats-mb-wrapper {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 40px;
                    background: #3535351A;
                    border-bottom: 1px solid #1A2B35;
                    padding: 12px 32px;
                    border-radius: 16px;
                    backdrop-filter: blur(4px);
                    position: relative;
                    z-index: 20;



                    .stats-items {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        z-index: 20;
                        flex: 1;
                        
    
                        &:not(:last-child) {
                            border-right: 1px solid #D8EBF6;
                            

                            @media screen and (max-width: 420px) {
                                padding-right: 20px;
                            }
                        }
                        
                        h2 {
                            color: #E4ECF2;
                            font-family: 'Exo';
                            font-size: 28px;
                            font-weight: 700;
                            line-height: 34px;
                            letter-spacing: 5px;
                            position: relative;
                            z-index: 20;

                            @media screen and (max-width: 420px) {
                                font-size: 18px;
                                font-weight: 600;
                                line-height: 26px;
                            }
                            
                        }
    
                        h3 {
                            background: linear-gradient(94.23deg, #079B9D -6.45%, #2C7EAD 115.88%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            text-fill-color: transparent;
                            font-family: 'Exo';
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 24px;
                            text-align: center;
                            text-transform: uppercase;


                            @media screen and (max-width: 420px) {
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 20px;
                            }
                            
                            
                        }
                    }

                }
            }

            .stats {
                display: flex;
                align-items: center;
                gap: 40px;
                background: #3535351A;
                border-bottom: 1px solid #1A2B35;
                padding: 12px 32px;
                border-radius: 16px;
                backdrop-filter: blur(4px);
                position: relative;
                z-index: 20;

                @media screen and (max-width: 820px) {
                    display: none;
                }

                .stats-items {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    align-items: center;
                    position: relative;
                    z-index: 20;
                    

                    &:not(:last-child) {
                        border-right: 1px solid #D8EBF6;
                        padding-right: 30px;
                    }
                    
                    h2 {
                        color: #E4ECF2;
                        font-family: 'Exo';
                        font-size: 32px;
                        font-weight: 700;
                        line-height: 42px;
                        letter-spacing: 5px;
                        position: relative;
                        z-index: 20;
                        
                    }

                    h3 {
                        background: linear-gradient(94.23deg, #079B9D -6.45%, #2C7EAD 115.88%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        font-family: 'Exo';
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 36px;
                        text-align: center;
                        text-transform: uppercase;
                        
                        
                    }
                }
            }

            .partners-chain {
                margin-top: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 30px;
                position: relative;
                z-index: 20;

                a:last-child {
                    img {
                        height: 20px;
                    }
                }

                img {
                    position: relative;
                    z-index: 20;
                    max-width: 90px;
                }
            }
        }
        .moving-glow-gradient {
            position: absolute;
            width: 400px;
            height: 400px;
            border-radius: 50%;
            background: #0B1F3DA8;
            mix-blend-mode: plus-lighter;
            top: 100px;
            left: 0;
            filter: blur(50px);
            // opacity: 0.5;
            animation: moveSideways 8s ease-out infinite;

            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }
}


@keyframes moveSideways {
    0%, 100% {
        left: 0;
    }
    // 20%, 30% {
    //     left: calc(50% - 200px); 
    // }
    50% {
        left: calc(100% - 400px);
    }
    100% {
        left: 0; 
    }
}

// ecosystem

.ecosystem-container {
    background: #070E12;
    position: relative;
    padding-bottom: 100px;

    // &::before {
    //     content: '';
    //     position: absolute;
    //     width: 100%;
    //     height: 140px;
    //     clip-path: polygon(0 0,100% 0,100% calc(100% - 110px),50% 100%,0 calc(100% - 110px));
    //     top: 0px;
    //     left: 0;
    //     background: #070E12;
    //     backdrop-filter: blur(20px);
    // }


    

    .ecosystem-wrapper {
        width: 86%;
        margin: 0 auto;
        padding-top: 30px;
        position: relative;
        z-index: 4;
    }
    .ecosystem-header {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 40px;
        align-items: center;

        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;

            align-items: start;
        }

        h3 {
            font-family: 'Exo 2';
            font-size: 48px;
            font-weight: 700;
            line-height: 58px;
            text-align: left;
            color: #C2CDD5;
            text-transform: uppercase;

            @media screen and (max-width: 768px) {
                font-size: 28px;
                line-height: 38px;
                text-align: left;
            }
            
        }

        p {
            font-family: Exo;
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            text-align: left;
            color: #A0ACB4;

            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 24px;
                text-align: left;
                
            }
        }
    }

    .ecosystem-ctx {
        margin-top: 40px;

        .ecosystem-ctx-menu {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 50px;
            width: 60%;
            padding: 8px 0;
            border-top: 1px solid #d8ebf61e;
            border-bottom: 1px solid #d8ebf61e;

            @media screen and (max-width: 768px) {
                width: 100%;
                overflow-x: auto;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
        .ecosystem-ctx-menu-item {
            font-family: Exo;
            font-size: 24px;
            font-weight: 500;
            line-height: 34px;
            text-align: left;
            color: #D5DDE2;
            cursor: pointer;

            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 24px
            }

            &.active-tab {
                background: linear-gradient(94.23deg, #079B9D -6.45%, #2C7EAD 115.88%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }

        }
        .ecosystem-tabs-ctx {
            margin-top: 40px;
            display: grid;
            grid-template-columns: 60% 40%;
            gap: 60px;

            @media screen and (max-width: 768px) {
                grid-template-columns: 1fr;
            }

            

            .ecosystem-tabs-ctx-items {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 30px;
                max-height: 530px;
                padding-bottom: 20px;
                overflow-y: auto;
                padding-top: 30px;
                padding-left: 27px;
                padding-right: 27px;

                @media screen and (max-width: 1300px) {
                    display: block;
                    padding-right: 0px;
                    padding-left: 0px;
                }

                &::-webkit-scrollbar {
                    display: none;
                }
            
            }

            .ecosystem-tabs-ctx-info {
                max-height: 530px;
                padding-bottom: 10px;
                overflow-y: auto;
                position: relative;

                

                // &::-webkit-scrollbar {
                //     display: none;
                // }
                @media screen and (max-width: 768px) {
                    display: none;
                }
                .tabs-social-logo {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 20px;
                }
                .tabs-info-content {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    align-items: flex-start;

                    h3 {
                        font-family: 'Exo 2';
                        font-size: 36px;
                        font-weight: 700;
                        line-height: 46px;
                        text-align: left;
                        color: #C2CDD5;

                    }

                    p {
                        font-family: 'Exo';
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 30px;
                        text-align: left;
                        color: #A0ACB4;

                    }
                }
            }
        } 
        // .gangwar-product-card {
        //     height: 220px;
        //     width: 100%;
        //     border-radius: 12px;
        //     border: 1px solid #8CAABB80;
        //     background: url('../../../images/gangstaverse/gangwar-icon.png');
        //     background-position: center;
        //     background-repeat: no-repeat;
        //     background-size: cover;
        //     position: relative;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;

        //     .gangwar-text {
        //         position: relative;
        //         z-index: 4;
        //         background: linear-gradient(180deg, #856F1F 0%, #E3C96E 100%);
        //         -webkit-background-clip: text;
        //         -webkit-text-fill-color: transparent;
        //         background-clip: text;
        //         text-fill-color: transparent;
        //     }

        //     &::after {
        //         content: '';
        //         position: absolute;
        //         height: 100%;
        //         width: 100%;
        //         background: linear-gradient(180deg, rgba(15, 15, 15, 1) 0%, rgba(15, 15, 15, 0.734) 35%, rgba(15, 15, 15, 0.734) 100%);
        //         z-index: 3;
        //         top: 0;
        //         left: 0;
        //         border-radius: 12px;
        //     }
        // }

        .ecosystem-chains {
            position: absolute;
            display: flex;
            align-items: center;
            gap: 6px;
            top: 8px;
            left: 10px;
            z-index: 5;

            .ecosystem-chain-logos {
                height: 32px;
                width: 32px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                &.ICON, &.TELEGRAM {
                    background: #358ABA;
                }
                &.BASE {
                    background: #004ff7;
                }
                &.SUI {
                    background: #4a9ef7;
                }
            }
        }
    }

    .tabs-content-bullet {
        padding-left: 20px;
        li {
        
            p {
                margin-left: 30px;
            }
        }
    }
}


// partners

.partner-container {
    padding-top: 50px;
    padding-bottom: 80px;
    background: #070E12;
    min-height: 80vh;

    .partner-wrapper {
        width: 86%;
        margin: 0 auto;
    }

    .partner-header {
        width: 60%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 18px;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
         
        h3 {
            font-family: 'Exo 2';
            font-size: 48px;
            font-weight: 700;
            line-height: 58px;
            text-align: center;
            color: #C2CDD5;
            text-transform: uppercase;

            @media screen and (max-width: 768px) {
                font-size: 28px;
                line-height: 38px;
            }

        }
        p {
            font-family: 'Exo';
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            color: #A0ACB4;

            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 24px;
                
            }
            

        }
    }

    .partners {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        flex-wrap: wrap;
        padding: 0 60px;

        @media screen and (max-width: 768px) {
            justify-content: space-evenly;
            
        }

        .partners-item {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 768px) {
               width: fit-content;
                
            }

            img {
                width: 210px;

                @media screen and (max-width: 768px) {
                    width: 90px;
                    
                }
            }
        }
    }
}

// roadmap

.roadmap-container {
    padding-top: 50px;
    padding-bottom: 80px;
    background: #070E12;
    min-height: 80vh;
    position: relative;

    // &::after {
    //     content: '';
    //     position: absolute;
    //     height: 50px;
    //     width: 100%;
    //     bottom: 0;
    //     left: 0;
    //     background: linear-gradient(180deg, rgba(5, 9, 13, 0) 0%, #35668A 100%);
    // }

    .roadmap-wrapper {
        width: 80%;
        margin: 0 auto;
    }

    .roadmap-header {
        width: 60%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 18px;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
        h3 {
            font-family: 'Exo 2';
            font-size: 48px;
            font-weight: 700;
            line-height: 58px;
            text-align: center;
            color: #C2CDD5;
            text-transform: uppercase;
            @media screen and (max-width: 768px) {
                font-size: 28px;
                line-height: 38px;
            }

        }
        p {
            font-family: 'Exo';
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            color: #A0ACB4;
            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 24px;
                
            }
            

        }
    }
    .roadmap-carousel {
        position: relative;
        width: 100%;

        .glow-gradient-circle {
            position: absolute;
            width: 650px;
            height: 650px;
            border-radius: 50%;
            background: #457785;
            mix-blend-mode: color-dodge;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            filter: blur(36px);
            opacity: 0.655;
            z-index: 10;
        }

        .prev-btn-wrapper {
            position: absolute;
            top: -5px;
            left: -70px;
            z-index: 10;
            height: 100%;
            @media screen and (max-width: 768px) {
                left: -50px;
                top: 0;
            }
            @media screen and (max-width: 420px) {
                left: -20px;
                top: 0;
            }

            &::before {
                content: '';
                position: absolute;
                width: 100px;
                height: 100%;
                background: #070E1280;
                filter: blur(15px);
                left: 0;
                top: 0;
                z-index: 5;
            }

            .prev-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(94.23deg, #079B9D -6.45%, #2C7EAD 115.88%);
                height: 55px;
                width: 55px;
                border-radius: 50%;
                cursor: pointer;
                position: relative;
                z-index: 6;

                @media screen and (max-width: 768px) {
                    height: 40px;
                    width: 40px;
                }

                &.disable-prev {
                    background: linear-gradient(136.01deg, rgba(195, 195, 195, 0.12) 11.81%, rgba(104, 104, 104, 0.12) 101.77%);
                    cursor: not-allowed;
                }
                
            }
        }
        .next-btn-wrapper {
            position: absolute;
            top: -5px;
            right: -70px;
            z-index: 10;
            height: 100%;

            @media screen and (max-width: 768px) {
                right: -50px;
                top: 0;
            }
            @media screen and (max-width: 420px) {
                right: -20px;
                top: 0;
            }

            // @media screen and (max-width: 768px) {
            //     display: none;
            // }

            &::before {
                content: '';
                position: absolute;
                width: 100px;
                height: 100%;
                background: #070e12;
                
                filter: blur(15px);
                left: -24px;
                top: 0;
                z-index: 5;
            }

            .next-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(94.23deg, #079B9D -6.45%, #2C7EAD 115.88%);
                height: 55px;
                width: 55px;
                border-radius: 50%;
                cursor: pointer;
                position: relative;
                z-index: 6;

                @media screen and (max-width: 768px) {
                    height: 40px;
                    width: 40px;
                }

                &.disable-next {
                    background: linear-gradient(136.01deg, rgba(195, 195, 195, 0.12) 11.81%, rgba(104, 104, 104, 0.12) 101.77%);
                    cursor: not-allowed;
                }
                
            }
        }
    }
    .roadmap-carousel-wrapper {
        margin-top: 100px;
        display: flex;
        align-items: flex-start;
        gap: 60px;
        max-width: 100%;
        overflow-x: scroll;
        position: relative;

        &::-webkit-scrollbar {
            display: none;
        }

        &::before {
            content: '';
            position: absolute;
            width: calc(100% + 230%);
            height: 2px;
            background: #F2F2F2;
            left: 130px;
            top: 24px;
        }

        
    }
}