//product collection
.product-collection-wrapper {
  .product-collection-wrapper-heading {
    font-weight: 400;
    font-size: 42px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.25px;
    color: #ffffff;
  }
  .product-collection {
    max-width: 1224px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    margin: 40px auto;
  }
}

//product card
.product-card {
  width: 448px;
  height: 230px;
  display: flex;
  padding: 2px;
  margin: 5px;
  flex-direction: column;
  justify-content: space-between;
  @include max-break-point(mobile-phone) {
    height: 260px;
  }
  @include max-break-point(tablet-landscape) {
    width: 402px;
    gap: 10px;
  }
  .pc-img {
    width: 100%;
    height: 310px;
  }
  .pc-heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.04em;
    color: #ffffff;
  }
  .pc-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.04em;
    color: rgba(255, 255, 255, 0.5);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .learn-more-size {
    width: 180px;
    font-size: 14px;
  }
}
