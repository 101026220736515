.footer {
  display: flex;
  height: 300px;
  gap: 20px;
  justify-content: flex-end;
  padding-bottom: 100px;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(5, 9, 13, 0) 0%, #35678a74 100%);
  background-color: #070E12;
  position: relative;
  

  .gradient-ellipse {
    height: 300px;
    width: 100%;
    background: #6D878B66;
    border-top-left-radius: 50% 150px; /* 50% of the width and 150px of the height */
    border-top-right-radius: 50% 150px; /* 50% of the width and 150px of the height */
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    mix-blend-mode: color-dodge;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(36px);
    border-radius: 50%;
  }

  .logoText {
    cursor: pointer;
    letter-spacing: 0.04em;
    color: var(--menu-heading);
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    position: relative;
    z-index: 23;
    .logoText-back {
      font-weight: 200;
    }
  }
  .footer-media {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    z-index: 23;
    .borderless-btn {
      background-color: #171717;
      border-radius: 100%;
      padding: 10px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
        background-color: #1e1e1e;
      }
    }
  }
  .copyright {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}
