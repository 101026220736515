$timeColor: rgba(255, 255, 255, 0.5);
$timelineText: #ffffff;
.timeline_show_more_button {
  margin: 0 auto;
  margin-top: 50px;
  width: fit-content;
}
.timeline-div {
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
  overflow: hidden;
  &.showLess {
    height: 650px;
    transition: height 1s ease-out;
    @include max-break-point(tablet) {
      height: 750px;
    }
  }
  &.showMore {
    height: 1350px;
    transition: height 1s ease-in;
    @include max-break-point(tablet) {
      height: 1750px;
    }
    @include max-break-point(mobile-phone) {
      height: 1490px;
    }
  }
}
.timelineItem {
  display: flex;
  justify-content: center;
  width: 50%;
  padding-right: 30px;
  height: 100px;
  &:nth-child(even) {
    align-self: flex-end;
    justify-content: center;
    padding-right: 0px;
    padding-left: 30px;
    .timelineItem-content {
      .bullet {
        right: auto;
        left: -100px;
      }
    }
  }
}
.timelineItem-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
  .bullet {
    top: 0px;
    width: 150px;
    background: linear-gradient(
      160.09deg,
      rgba(1, 27, 32, 0.9) -8.35%,
      rgba(0, 2, 4, 0.9) 76.1%
    );
    border: 1px solid #354446;
    backdrop-filter: blur(5.5px);
    position: absolute;
    height: 30px;
    // width: 30px;
    right: calc(-65% - 10px);
    z-index: 2;
    text-align: center;
  }
}
.time {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.001em;
  color: $timeColor;
}
.timeline-text {
  font-weight: 400;
  font-size: 36px;
  letter-spacing: 0.04em;
  color: #ffff;
  text-align: center;
  margin-bottom: 10px;
  line-height: 120%;
  color: $timelineText;
  text-transform: capitalize;
}
.timeline-text-details {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.04em;
  line-height: 160%;
  max-width: 927px;
  padding: 5px 10px;
  opacity: 0.6;
  margin: auto;
  color: $timelineText;
}
.event {
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: $timelineText;
}
.timeline-bg-image-left {
  position: absolute;
  top: 15%;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 2, 4, 0) 0%, #000204 88.54%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../../images/gangstaverse/character1.png); /* Chrome 10-25, Safari 5.1-6 */
  background-size: cover;
  background-position: center;
  margin: 10% 0 0 10%;
  width: 320px;
  height: 333px;
}
.timeline-bg-image-right {
  position: absolute;
  top: 30%;
  right: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 2, 4, 0) 0%, #000204 88.54%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../../images/gangstaverse/character2.png); /* Chrome 10-25, Safari 5.1-6 */
  background-size: cover;
  background-position: center;
  margin: 10% 15% 0 0%;
  width: 340px;
  height: 360px;
}

@include max-break-point(large-desktop) {
  .timeline-bg-image-left {
    margin: 5% 0 0 5%;
    width: 340px;
    height: 360px;
  }
  .timeline-bg-image-right {
    margin: 10% 10% 0 0%;
    width: 340px;
    height: 360px;
  }
}

@include max-break-point(tablet-landscape) {
  .timeline-bg-image-left,
  .timeline-bg-image-right {
    display: none;
  }
}
@include max-break-point(tablet) {
  .timeline-text {
    font-size: 30px;
  }
  .timeline-text-details {
    font-size: 14px;
  }
}
@include max-break-point(mobile-phone) {
  .timeline-text {
    font-size: 25px;
  }
  .timeline-text-details {
    font-size: 13px;
  }
}

//  timeline
.timeline-section {
  min-height: 100vh;
  padding: 70px 15px;
}
.timeline-items {
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.timeline-items::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #2f363e;
  left: calc(50% - 1px);
}
.timeline-item {
  margin-bottom: 40px;
  width: 100%;
  position: relative;
}
.timeline-item:last-child {
  margin-bottom: 0;
}
.timeline-item:nth-child(odd) {
  padding-right: calc(63% + 10px);
}
.timeline-item:nth-child(even) {
  padding-left: calc(63% + 10px);
}
.timeline-dot {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 166px;
  height: 47px;
  background: linear-gradient(
    160.09deg,
    rgba(1, 27, 32, 0.9) -8.35%,
    rgba(0, 2, 4, 0.9) 76.1%
  );
  border: 1px solid #354446;
  backdrop-filter: blur(5.5px);
  position: absolute;
  left: calc(43% - 8px);
}
.timeline-date {
  font-size: 18px;
  color: #eaa023;
  margin: 6px 0 15px;
}
.timeline-content {
  background: linear-gradient(
    160.09deg,
    rgba(1, 27, 32, 0.9) -8.35%,
    rgba(0, 2, 4, 0.9) 76.1%
  );
  border: 1px solid #354446;
  backdrop-filter: blur(5.5px);
  padding: 15px;
  // border-radius: 5px;
  position: relative;
  z-index: 1;
}
.timeline-item:nth-child(odd)::after {
  content: "";
  position: absolute;
  height: 0px;
  top: 10%;
  width: 200px;
  right: 55%;
  border-top: 2px solid #354446;
}
@include max-break-point(tablet) {
  .timeline-item:nth-child(odd)::after,
  .timeline-item:nth-child(even)::after {
    display: none;
    width: 0px;
  }
  .timeline-content:nth-child(odd)::before,
  .timeline-content:nth-child(even)::before {
    content: "";
    position: absolute;
    height: 0px;
    top: 10%;
    width: 29px;
    left: -30px;
    border-top: 2px solid #354446;
  }
}
.timeline-item:nth-child(even)::after {
  content: "";
  position: absolute;
  top: 10%;
  height: 0px;
  width: 200px;
  right: 26%;
  border-top: 2px solid #354446;
}
.timeline-item:last-child::after {
  content: "";
  display: none;
}
.timeline-content h3 {
  font-size: 20px;
  color: #ffffff;
  line-height: 40px;
  text-transform: capitalize;
  font-weight: 500;
}
.timeline-content p {
  color: #c8c8c8;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}
.events-list {
  font-size: 14px;
  font-weight: 100;
  padding: 3px;
}
.blur-text {
  font-size: 12px;
}
.text-blur-soon {
  // filter: blur(1.5px) !important;
}
@include max-break-point(tablet) {
  .timeline-items::before {
    left: 7px;
    top: 1.5%;
    height: 94%;
  }
  .timeline-item:nth-child(odd) {
    padding-right: 0;
    text-align: left;
  }
  .timeline-item:nth-child(odd),
  .timeline-item:nth-child(even) {
    padding-left: 37px;
  }
  .timeline-dot {
    left: 0;
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer;
}
