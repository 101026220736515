.nft-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.nft-detail-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  @include max-break-point(mobile-phone) {
    flex-direction: column;
  }
}

.nft-detail-input {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.nft-detail-inputfield {
  background: #fff;
  border: none;
  padding: 20px;
}

.ledger-address-body {
  .ledger-address-list {
    padding: 20px 0px;

    .address-container {
      padding: 5px 0px;
      text-overflow: ellipsis;
      cursor: pointer;

      .address-text {
        font-size: 16px;

        &:hover {
          font-style: italic;
        }
      }

      &.active {
        color: green;
      }
    }
  }
}

.address-text {
  font-size: 16px;
}

.button-group-ledger {
  display: flex;
  flex-direction: row;
}

.ledger-wrapper {
  .pagination {
    list-style: none;
    margin-bottom: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
