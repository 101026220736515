
.roadmapItem-wrapper {
    width: 280px;

    .year {
        width: 130px;
        height: 47px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        background: linear-gradient(94.23deg, #079B9D -6.45%, #2C7EAD 115.88%);
        position: relative;
        z-index: 6;

        


        p {
            font-family: 'Satoshi';
            font-size: 20px;
            font-weight: 900;
            line-height: 30px;
            
            color: #FFFFFF;

            @media screen and (max-width: 768px) {
                font-size: 18px;
            }
        }
        span {
            font-family: 'Satoshi';
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            color: #FFFFFF;

            @media screen and (max-width: 768px) {
                font-size: 18px;
            }
        }
    }
    .year2 {
        width: 130px;
        height: 47px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        background: linear-gradient(136.01deg, rgba(195, 195, 195, 0.12) 11.81%, rgba(104, 104, 104, 0.12) 101.77%);
        backdrop-filter: blur(5px);
        position: relative;
        z-index: 6;

        


        p {
            font-family: 'Satoshi';
            font-size: 20px;
            font-weight: 900;
            line-height: 30px;
            
            color: #FFFFFF;

            @media screen and (max-width: 768px) {
                font-size: 18px;
            }
        }
        span {
            font-family: 'Satoshi';
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            color: #FFFFFF;

            @media screen and (max-width: 768px) {
                font-size: 18px;
            }
        }
    }
    .roadmap-milestones {
        margin-top: 14px;
        width: 280px;
        padding: 0 24px;
        ul {
            list-style-type: disc;
            width: 100%;

            a {
                text-decoration: underline;
                text-decoration-color: #f7f8f9;
            }
            

            li {
                font-family: 'Saira';
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                text-align: left;
                color: #D3E1EB;
                padding: 0 4px;
                position: relative;
                z-index: 20;

                a {}

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                    line-height: 20px;
                }



            }
        }
    }
}