html {
  --white: #ffffff;
  --secondary-color: #bebebe;
  --dm-san: "DM Sans", sans-serif;
  --poppins: "Poppins", sans-serif;
  --clashDisplay: "Clash Display", sans-serif;
  --exo: "Exo", sans-serif;
  --cinzel: "Cinzel", serif;
  --primary-color: #068385;
  --primary-blue: #079b9d;
  --menu-desc-text: rgba(255, 255, 255, 0.8);
  --menu-heading: #ffffff;
  --primary-bg: #070E12;
}
