.artViewOverlay {
  background: rgba(0, 0, 0, 0.32);
  position: fixed;
  z-index: 99;
  height: 110vh;
  width: 100vw;
  top: -20px;
  left: 0;
  border-radius: 0;
  overflow-y: scroll;
  &.art-view {
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(4px);
  }
}
.infoBackground {
  background-color: rgba(49, 94, 251, 0.07);
}

.art-view-modal {
  position: relative;
  display: flex;
  text-align: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .close {
    position: absolute;
    top: 5%;
    right: 2%;
    cursor: pointer;
    z-index: 9999;
  }

  .art {
    align-self: center;
    cursor: grab;
    z-index: 100;
  }
}

.zoom-in-out {
  z-index: 101;
  display: flex;
  gap: 20px;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}

.reset-zoom {
  position: absolute;
  right: 2%;
  bottom: 5px;
  cursor: pointer;
}

.zoom-value {
  @include disable-selection;
}
