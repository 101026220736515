.story-card {
  // background: #171717;
  border-radius: 8px;
  display: flex;
  width: 90%;
  padding: 20px;
  gap: 30px;
  align-items: center;
  cursor: pointer;
  @include max-break-point(tablet) {
    flex-direction: column;
  }
}

.story-card:hover {
  background: #171717;
}

.story-subtitle {
  width: 80%;
  margin: auto;
  @include max-break-point(tablet-landscape) {
    width: 100%;
  }
  @include max-break-point(tablet) {
    width: 100%;
  }
}

.story-img {
  width: 60px;
  @include max-break-point(tablet) {
    width: 105px;
  }
}

.story-heading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  min-width: 150px;
}

.story-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.04em;
  font-weight: bolder;
  @include max-break-point(tablet) {
    text-align: center;
  }
}

.level-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.level-text {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 8px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.004em;

  color: #010708;
  background: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
  width: fit-content;
  display: flex;
  justify-content: center;
}

.story-desc {
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.04em;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  position: relative;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
