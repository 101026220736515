.flex {
  @include flexbox;

  &-row {
    @extend .flex;
    flex-direction: row;
  }

  &-col {
    @extend .flex;
    flex-direction: column;
  }

  &-align-items-center {
    @extend .flex;
    align-items: center;
  }

  &-justify-content-center {
    @extend .flex;
    justify-content: center;
  }

  &-justify-content-sa {
    @extend .flex;
    justify-content: space-around;
  }

  &-wrap {
    @extend .flex;
    flex-wrap: wrap;
  }
}

@mixin pd-l($width) {
  padding-left: $width;
}

@mixin pd-r($width) {
  padding-right: $width;
}

@mixin pd-t($height) {
  padding-top: $height;
}

@mixin pd-b($height) {
  padding-bottom: $height;
}

//mixin (px-10)
// loop
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.green {
  color: var(--primary-color);
}

@include max-break-point(mobile-phone) {
  .adjust-font {
    font-size: 14px !important;
  }
  .adjust-heading-3 {
    font-size: 30px !important;
  }
}
