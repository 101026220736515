.gv-div {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  max-width: 1440px;
  margin: auto;
  flex-wrap: wrap;
  background-color: black;
  .gangstabet,
  .emeraldCity {
    border: 3px solid #068385;
    box-shadow: 2px;
    padding: 5px;
    border-radius: 10px;
  }
  .enabled {
    cursor: pointer;
    margin-top: 20px;
  }
  .disabled {
    cursor: not-allowed;
    margin-top: 20px;
  }
}
