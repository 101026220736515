.nftQueue {
  background: linear-gradient(
    156.52deg,
    rgba(1, 27, 32, 0.6) -3.19%,
    rgba(0, 2, 4, 0.6) 77.73%
  );
  border: 1px solid #ffffff;
  backdrop-filter: blur(5.5px);
  width: 900px;
  height: 486px;
  @include max-break-point(tablet-landscape) {
    width: 750px;
  }
  @include max-break-point(tablet) {
    width: 550px;
  }
  margin: 30px auto;
  overflow-x: auto;
  overflow-y: auto;

  padding: 20px 0px;
}
.queue-heading {
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: 0.15px;
  color: #ffffff;
  text-align: center;
}

.queue-main-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  min-height: 300px;
}
.queue-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  text-align: left;
  // padding-left: 10%;
  font-weight: 200;
}
tr {
  text-align: start;
  height: 55px;
}
td {
  text-align: start;
  // padding-left: 10%;
}
.clickable-queue-td {
  color: var(--primary-color);
  cursor: pointer;
}
td:nth-child(1) {
  text-align: center;
  padding-left: 3% !important;
}
tr:nth-child(even) {
  background: rgba(16, 142, 162, 0.26);
}
td:nth-child(2) {
  padding-left: 7% !important;
}
th:nth-child(2) {
  padding-left: 7% !important;
}

.queue-sm-wrapper {
  margin: 30px auto;
  .queue-sm {
    &:nth-child(odd) {
      background: rgba(16, 142, 162, 0.26);
    }
    padding: 15px;
    display: flex;
    @include max-break-point(tablet) {
      width: 60%;
      margin: auto;
    }
    @include max-break-point(mobile-phone) {
      width: 90%;
    }
    // justify-content: space-between;
    gap: 30px;
    .queue-sm-text {
      display: flex;
      width: 120px;
      flex-direction: column;
      // justify-content: space-between;
      .queue-sm-name {
        .queue-name-label {
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0.004em;

          color: #ffffff;
        }
        .queue-name-value {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.004em;
          color: #079b9d;
        }
      }
      .queue-sm-tx {
        .queue-tx-label {
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0.004em;

          color: #ffffff;
        }
        .queue-tx-value {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.004em;
          color: #079b9d;
        }
      }
    }
    .queue-sm-reserved {
      .queue-res-label {
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.004em;

        color: #ffffff;
      }
      .queue-res-value {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.004em;
        color: #079b9d;
      }
    }
  }
}
