// product banner
.topBanner-emeraldCity {
  background-image: url("../../../images/emeraldCity/banner.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

// product banner
.topBanner-gangstabet {
  background-image: url("../../../images/gangstabet/banner.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topBanner-text {
  margin: auto;
  width: 80%;
  padding: 80px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.04em;
  opacity: 0.6;

  @include max-break-point(tablet-landscape) {
    width: 90%;
    padding: 40px;
  }
  
  @include max-break-point(tablet) {
    width: 95%;
    padding: 40px 20px;
    font-size: 18px;
  }
  @include max-break-point(mobile-phone) {
    width: 100%;
    font-size: 17px;
  }
}

// product info
.ecp-info-wrapper {
  @include max-break-point(tablet) {
    width: 80%;
  }
  @include max-break-point(tablet-landscape) {
    width: 90%;
  }
  @include max-break-point(mobile-phone) {
    width: 100%;
  }
  width: 65%;
  margin: 25px auto 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ecp-info-text {
    opacity: 0.6;
    text-indent: 50px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
  padding: 20px 20px 0px 20px;
  .ytp-video {
    width: 100%;
    height: 430px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @include max-break-point(mobile-phone) {
      height: 300px;
    }
    iframe {
      border-radius: 8px;
      width: 600px;
      height: 310px;
      @include max-break-point(mobile-phone) {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// product crown token
.crownToken-wrapper {
  width: 100%;
  background: linear-gradient(180deg, #000204 14.55%, #011313 100%);
  .crownToken {
    display: flex;
    width: 80%;
    justify-content: center;
    gap: 20%;
    align-items: center;
    margin: auto;
    padding-bottom: 20px;
    @include max-break-point(tablet) {
      flex-direction: column;
      width: 100%;
    }
    .crownToken-info {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      @include max-break-point(tablet) {
        justify-content: center;
        align-items: center;
      }

      .crownToken-heading {
        font-weight: 400;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.04em;
        color: #ffffff;
      }
      .crownToken-paragraph {
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        opacity: 0.6;

        letter-spacing: 0.04em;

        color: #ffffff;
      }
      .see-all-btn {
        width: 180px;
      }
      @include max-break-point(tablet) {
        width: 90%;
        order: 2;
      }
    }
    .spinner-wrapper {
      position: relative;
      .spinner {
        width: 300px;
        -webkit-animation: spin 5s linear infinite;
        -moz-animation: spin 5s linear infinite;
        animation: spin 5s linear infinite;
        transform-origin: 50% 50%;
        @include max-break-point(mobile-phone) {
          width: 240px;
        }
      }
      .crown {
        position: absolute;
        width: 200px;
        left: 15%;
        top: 15%;
        @include max-break-point(tablet) {
          width: 160px;
          left: 22%;
          top: 24%;
        }
        @include max-break-point(mobile-phone) {
          width: 135px;
        }
      }
    }
  }
}

// product gbet token
.gbetToken-wrapper {
  width: 100%;
  background: linear-gradient(180deg, #000204 14.55%, #011313 100%);
  .gbetToken {
    display: flex;
    width: 80%;
    justify-content: center;
    gap: 20%;
    align-items: center;
    margin: auto;
    padding-bottom: 20px;
    @include max-break-point(tablet) {
      flex-direction: column;
      width: 100%;
    }
    .gbetToken-info {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .gbetToken-heading {
        font-weight: 400;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.04em;
        color: #ffffff;
      }
      .gbetToken-paragraph {
        font-weight: 400;
        font-size: 16px;
        opacity: 0.6;
        line-height: 160%;

        letter-spacing: 0.04em;

        color: #ffffff;
      }
      .see-all-btn {
        width: 180px;
      }
      @include max-break-point(tablet) {
        width: 90%;
        order: 2;
      }
    }
    .spinner-wrapper {
      position: relative;
      .spinner {
        width: 300px;
        -webkit-animation: spin 5s linear infinite;
        -moz-animation: spin 5s linear infinite;
        animation: spin 5s linear infinite;
        transform-origin: 50% 50%;
      }
      .gbet {
        position: absolute;
        width: 200px;
        left: 15%;
        top: 15%;
      }
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

// product info
.gsp-info-wrapper {
  @include max-break-point(tablet) {
    width: 80%;
  }
  @include max-break-point(tablet-landscape) {
    width: 90%;
  }
  @include max-break-point(mobile-phone) {
    width: 100%;
  }
  width: 75%;
  margin: 25px auto 80px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  .gsp-info-heading {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #ffffff;
  }
  .gsp-info-text {
    text-indent: 50px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    opacity: 0.6;
    letter-spacing: 0.02em;
    color: #ffffff;
    @include max-break-point(mobile-phone) {
      font-size: 14px;
    }
  }
  padding: 20px 20px 0px 20px;
}
.gsp-banner {
  width: 100%;
  margin-bottom: 80px;
}
