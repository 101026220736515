.btn {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.0125em;
  text-transform: uppercase;
  color: #0b1113;
  padding: 5px 38px;
  cursor: pointer;
  border: none;
  width: 175px;
  height: 45px;
  @include max-break-point(mobile-phone) {
    height: 45px;
    font-size: 12px;
  }
  clip-path: polygon(
    30px 0%,
    calc(100%) 0%,
    100% 30px,
    100% calc(100% - 30px),
    calc(100% - 30px) 100%,
    30px 100%,
    0% calc(100%),
    0% 30px
  );
  text-decoration: none;
  font-family: arial;
  position: relative;
  overflow: hidden;
  transition: all 0.35s;
  font-weight: 500;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0%;
    left: -110%;
    background: var(--primary-color);
    z-index: -1;
    transition: all 0.35s;
    opacity: 0.5;
    width: 190px;
    height: 62px;
    clip-path: polygon(
      0px 0%,
      calc(100%) 0%,
      100% 30px,
      100% calc(100% - 65px),
      calc(100% - 30px) 100%,
      30px 100%,
      0% calc(100%),
      0% 30px
    );
  }
  &:after {
    position: absolute;
    content: "";
    width: 110%;
    height: 100%;
    top: 0%;
    left: -110%;
    background: var(--primary-color);
    z-index: -1;
    transition: all 0.35s;
    transition-delay: 0.2s;
    clip-path: polygon(
      0px 0%,
      calc(100%) 0%,
      100% 30px,
      100% calc(100% - 65px),
      calc(100% - 30px) 100%,
      30px 100%,
      0% calc(100%),
      0% 30px
    );
    @include max-break-point(mobile-phone) {
      left: -108%;
    }
  }
  &:hover {
    color: #fff;

    &:before,
    &:after {
      left: 0;
    }
  }
}

.btn-ghost {
  background: inherit;
  color: #fff;
  background-color: #000204 ;
}

.btn-fullwidth {
  width: 100%;
}

.outline-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 179px;
  height: 47px;
  clip-path: polygon(
    31px 0%,
    calc(100%) 0%,
    100% 31px,
    100% calc(100% - 31px),
    calc(100% - 31px) 100%,
    31px 100%,
    0% calc(100%),
    0% 31px
  );
}

.btn-disabled {
  &:hover {
      color: black !important;
  
      &:before,
      &:after {
        left: -110% !important;
      }
    }
}

.primary-btn {
  background: linear-gradient(94.23deg, #079B9D -6.45%, #2C7EAD 115.88%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font-family: 'Exo';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 16px 32px;
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;

  &:disabled {
    background: linear-gradient(94.23deg, rgba(26, 26, 26, 0.6) -6.45%, rgba(21, 21, 21, 0.6) 115.88%);
    opacity: 0.7;
    cursor: not-allowed;
  }

}

.btn-link {
  text-decoration: none;
}